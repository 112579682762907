@import "./Variables";

.navbar {
  background-color: $brand_bg_color1;
}
.navbar-brand {
  cursor: pointer;
}
.navbar .nav-link {
  color: $brand_text_color1;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}
.navbar .nav-link:hover {
  color: $brand_text_color7;
}
.navbar_toggler {
  width: 42px;
  height: 42px;
  background: $brand_bg_color2;
  border-radius: 50%;
  border: 0px;
  color: $brand_bg_color1;
  display: block;
  align-items: center;
  padding: 0;
  justify-content: center;
  padding: 0px;
}
.navbar_toggler span {
  display: block;
  width: 20px;
  height: 1px;
  background-color: $brand_bg_color1;
  margin: 5px auto;
}
.offcanvas {
  background-color: $brand_bg_color1;
}
.burger_header {
  display: none;
}

.burger_header_scroll {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: +999;
  display: block;
}
.navbar .nav-link.notification {
  background-color: $brand_bg_color2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.navbar .nav-link.available_credit {
  background-color: $brand_bg_color2;
  width: fit-content;
  height: 40px;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #ffffff;
  padding: 0px 15px;
}
.navbar .nav-link.available_credit div {
  height: inherit;
  line-height: 1.1;
}
.logged_user {
  padding: 0px;
  display: inline-flex;
  align-items: center;
}
.logged_user img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  // border: 2px solid $brand_bg_color2;
  background-color: #dadada;
}
.logged_user_nm {
  position: relative;
  z-index: -1;
  height: 30px;
  line-height: 30px;
  padding: 0px 10px 0px 15px;
  background-color: $brand_bg_color2;
  margin-left: -10px;
  border-radius: 0px 5px 5px 0px;
  font-size: 16px;
  font-weight: 500;
  color: $brand_white;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
}

.user_dash_nav .nav-item {
  margin: 5px auto;
  width: 100%;
}
.user_dash_nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  // background: rgba(0, 80, 79, 0.28);
  border-radius: 8px;
}
.user_dash_nav .nav-item .nav-link.active {
  background: $brand_bg_color2;
  border-radius: 8px;
}
.user_dash_nav .nav-item .nav-link .user_nav_link_icon {
  margin-right: 10px;
}
.user_dash_nav .nav-item .nav-link.nav-link .user_nav_link_icon svg path {
  fill: $brand_bg_color4;
}
.user_dash_nav
  .nav-item
  .nav-link.nav-link.active
  .user_nav_link_icon
  svg
  path {
  fill: $brand_bg_color1;
}

.user_dash_nav .nav-item .nav-link .user_nav_link_text {
  color: $brand_bg_color4;
  font-weight: 500;
  font-size: 16px;
}
.user_dash_nav .nav-item .nav-link.active .user_nav_link_text {
  color: $brand_white;
}
.logout_link {
  // width: calc(100% - 20px);
  width: calc(100% - 0px);
  height: 48px;
  border: 2px solid $brand_bg_color2;
  color: $brand_bg_color2;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 30px;
  position: relative;
  // position: absolute;
  bottom: 30px;
  // left: 10px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout_link:hover {
  opacity: 0.6;
}
.login_btn {
  border: 1px solid $brand_bg_color2;
  color: $brand_bg_color2;
  border-radius: 10px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.login_btn:hover {
  color: $brand_white !important;
  background-color: $brand_bg_color2;
}

.logged_user .dropdown-toggle::before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  padding: 12px 28px 12px 20px;
  border-radius: 6px;
  background-color: $brand_bg_color2;
}
.logged_user .dropdown-toggle::before {
  // height: 0!important;
  // width: 0!important;
  content: none !important;
}
.logged_user .dropdown-toggle::after {
  color: $brand_white;
  content: none !important;
  // height: 0!important;
  // width: 0!important;
}
.main_header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: +99999;
}
.main_header .my_menu,
.main_header_fixed .my_menu {
  display: none;
}
.main_header .hdr_one {
  display: block;
}
.main_header_fixed .my_menu.hdr_four {
  display: block;
}
.hdr_eight_show .my_menu.hdr_four {
  display: none;
}
.hdr_eight_show .my_menu.hdr_eight {
  display: block;
}
// .main_header .hdr_two{
//     display: none;
// }
// .main_header_fixed .hdr_one{
//     display: none;
// }

.common_hdr_style .my_menu .input-group,
.main_header_fixed .my_menu .input-group {
  background-color: #ffffff;
  border-radius: 10px;
}
.common_hdr_style .my_menu .input-group .form-control,
.main_header_fixed .my_menu .input-group .form-control {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  border: 0px;
  border-radius: 10px;
}
.common_hdr_style .my_menu .input-group .btn,
.main_header_fixed .my_menu .input-group .btn {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0px;
  border-radius: 10px;
}
.common_hdr_style .my_menu .input-group .btn,
.main_header_fixed .my_menu .input-group .btn {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0px;
  border-radius: 10px;
}

.common_hdr_style .navbar-nav .logged_user .dropdown-menu {
  min-width: 200px;
  border-radius: 8px;
  background: #e7e8ec;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.common_hdr_style .navbar-nav .logged_user .dropdown-menu .dropdown-item {
  padding: 10px;
  background: #e7e8ec;
  color: $brand_text_color4;
}
.common_hdr_style .navbar-nav .dropdown-menu .dropdown-item svg {
  margin-right: 8px;
}
